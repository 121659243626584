<template>
  <section id="fale-conosco" class="fale-conosco">
    <h1 class="titulo">fale conosco</h1>

    <div class="forms-section">
      <form @submit.prevent="confirm()">
        <label for="nome">nome</label>
        <input
          name="nome"
          required
          placeholder="seu nome"
          type="text"
          v-model="nome"
        />

        <label for="telefone">telefone</label>
        <input
          name="telefone"
          required
          pattern="[\d]{10,}"
          placeholder="seu número com DDD"
          type="text"
          v-model="telefone"
        />

        <label for="email">Seu email</label>
        <input
          name="email"
          required
          pattern="^[^@]+@[^@]+\.[^@]+$"
          placeholder="insira seu melhor e-mail"
          type="text"
          v-model="email"
        />
        <div class="submit">
          <button @click="getFormData(), $emit('send'), confirm()">
            enviar
          </button>
          <div v-if="entrada == 1" class="correct">
            Dados enviados com sucesso
          </div>
          <div v-else-if="entrada == 2" class="wrong">
            Preencha os dados corretamente
          </div>
        </div>
      </form>
      <img src="../assets/pessoa.png" alt="" />
    </div>
  </section>
</template>

<script>
let data = {
  nome: '',
  telefone: '',
  email: '',
  fromEmail: 'testeprojetointeri@gmail.com',
  fromEmailPass: 'mewetewrapxmlgfk',
  toEmail: 'rafarrsobrinho@gmail.com',
  port: 4000
}

export { data }

export default {
  name: 'FaleConosco',
  emits: ['send'],
  data() {
    return {
      nome: '',
      telefone: '',
      email: '',
      entrada: 3
    }
  },
  methods: {
    confirm() {
      let elements = document.querySelectorAll('form input')

      let style1 = getComputedStyle(elements[0]).borderColor
      let style2 = getComputedStyle(elements[1]).borderColor
      let style3 = getComputedStyle(elements[2]).borderColor

      if (
        style1 == 'rgb(0, 128, 0)' &&
        style2 == 'rgb(0, 128, 0)' &&
        style3 == 'rgb(0, 128, 0)'
      ) {
        return (this.entrada = 1)
      } else {
        return (this.entrada = 2)
      }
    },
    getFormData() {
      data.nome = this.nome
      data.telefone = this.telefone
      data.email = this.email

      this.nome = ''
      this.telefone = ''
      this.email = ''
    }
  }
}
</script>

<style scoped>
.fale-conosco {
  background: #0f597d;
  display: flex;
  flex-direction: column;
  align-items: center;

  font-family: Harabara;
  color: #ffffff;
  font-weight: 700;
}

.forms-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 44px;
  max-width: 1140px;
  padding-top: 60px;
  width: 100%;

  background: #ffffff;
  border-radius: 32px;

  font-family: Montserrat;
  font-size: 20px;
  font-weight: 400;
  color: black;
}

.forms-section label {
  margin-left: 14px;
}

.forms-section form {
  display: flex;
  flex-direction: column;
  margin-bottom: 38px;
  max-width: 552px;
  width: 100%;
  margin: 0px 10px 38px 10px;
}

.forms-section img {
  max-width: 380px;
  width: 100%;
  height: 100%;
  max-height: 260px;
  margin: 0px 10px 56px 10px;
}

.forms-section input {
  font-size: 16px;
  border: 2px solid #555555;
  border-radius: 48px;
  border-radius: 48px;

  margin-top: 6px;
  margin-bottom: 16px;

  height: 50px;
  max-width: 607px;
  width: 90%;

  outline: none;
}

.forms-section input:valid {
  border: green solid 2px;
}

.forms-section input:focus:invalid {
  border: red solid 2px;
}

input[type='text'] {
  font-size: 16px;
  padding-left: 20px;
}

.forms-section button {
  margin-top: 8px;
  border: 2px solid #159ad1;
  color: #159ad1;
  border-radius: 48px;
  background: #ffffff;
  font-size: 16px;

  height: 64px;
  width: 190px;
}
.forms-section button:hover {
  cursor: pointer;
  background: #159ad1;
  color: white;
}

.submit {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 15px;
}

.correct {
  width: 200px;
  border-radius: 30px;
  color: green;
}

.wrong {
  width: 200px;
  border-radius: 30px;
  color: red;
}
</style>

<template>
  <header class="home__header">
    <img src="../assets/logo-interi.png" alt="" />
    <nav class="home__header__item" :class="{ openWrapper: burgerClick }">
      <a href="#quem-somos" class="home__header__item__link">quem somos</a>
      <a href="#solucoes" class="home__header__item__link">soluções</a>
      <a href="#depoimentos" class="home__header__item__link">depoimentos</a>
      <a href="#artigos" class="home__header__item__link">artigos</a>
      <a href="#membros" class="home__header__item__link">membros</a>
      <a
        href="#fale-conosco"
        class="home__header__item__link home__header__item__link--faleConosco"
        >fale conosco
      </a>
    </nav>
    <div class="burger-wrapper" @click="burgerClick = !burgerClick">
      <div class="menu-burger-item"></div>
      <div class="menu-burger-item"></div>
      <div class="menu-burger-item"></div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      burgerClick: false
    }
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm
    }
  }
}
</script>

<style scoped>
.home__header {
  z-index: 100;
  background: white;
  position: fixed;
  display: flex;
  justify-content: space-between;
  height: 100px;
  width: 100%;
}
.home__header img {
  margin: 18px 0px 8px 7.5vw;
}
.home__header__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.5vh 7.5vw 0px 0px;
  flex-wrap: wrap;
  max-width: 845px;
  width: 100%;
}
/* burgerrrr */
.burger-wrapper {
  cursor: pointer;
  position: relative;
  top: 50%;
  right: 15%;
  display: none;
  flex-direction: column;
}
.menu-burger-item {
  background-color: black;
  height: 5px;
  width: 40px;
  margin: 3px;
  border-radius: 15px;
}
@media (max-width: 950px) {
  .home__header__item {
    position: absolute;
    right: -1000px;
  }
  .burger-wrapper {
    display: flex;
  }
  .openWrapper {
    display: relative;
  }
}
.home__header__item__link {
  font-size: 1.25rem;
  margin: 0 15px 0px 0px;
  color: #0f597d;
  text-decoration: none;
  font-family: var(--font);
}
.home__header__item__link--faleConosco {
  border: 2px solid #0f597d;
  padding: 5px 15px;
  border-radius: 20px;
}
.home__header__item__link:hover {
  cursor: pointer;
  color: #0c435f;
}
/* open burgerrr */
.openWrapper {
  display: flex;
  width: 350px;
  top: 80px;
  right: -30px;
  background: white;
  flex-direction: column;
  height: 310px;
}
.openWrapper a {
  margin-bottom: 10px;
}
</style>

<template>
  <section id="membros" class="membros">
    <h1 class="titulo" style="color: #0f597d">membros</h1>
    <v-slide-group>
      <v-slide-item>
        <Membro>
          <img src="../assets/membros/4393-camila-alves.png" alt="" />
          <h3>Camila da Silva</h3>
          <p>VP e Diretora de RH</p>
          <a
            href="https://www.linkedin.com/in/camila-da-silva-alves-1a1517212/"
            target="_blank"
          ></a> </Membro
      ></v-slide-item>

      <v-slide-item>
        <Membro>
          <img src="../assets/membros/gabi-essi.png" alt="" />
          <h3>Gabriela Ribeiro</h3>
          <p>Diretora de Projetos</p>
          <a
            href="https://www.linkedin.com/in/essi-gabriela/"
            target="_blank"
          ></a> </Membro
      ></v-slide-item>

      <v-slide-item>
        <Membro>
          <img src="../assets/membros/IMG_4320 1.png" alt="" />
          <h3>Nicolle Lopes</h3>
          <p>Diretora de Marketing</p>
          <a
            href="https://www.linkedin.com/in/nicklv24/"
            target="_blank"
          ></a> </Membro
      ></v-slide-item>

                  <v-slide-item>
        <Membro>
          <img src="../assets/membros/4374-camila-terra.png" alt="" />
          <h3>Camila Talgatti</h3>
          <p>Gerente de Projetos</p>
          <a
            href="https://www.linkedin.com/in/camila-talgatti-terra-0314181bb/"
            target="_blank"
          ></a> </Membro
      ></v-slide-item>

      <v-slide-item>
        <Membro>
          <img src="../assets/membros/4279-mateus-corazza.png" alt="" />
          <h3>Mateus Corazza</h3>
          <p>Gerente de Projetos</p>
          <a href="https://www.linkedin.com/in/mateus-corazza-70a92b235/" target="_blank"></a> </Membro
      ></v-slide-item>

      <v-slide-item>
        <Membro>
          <img src="../assets/membros/9957-matheus-henrique.png" alt="" />
          <h3>Matheus Henrique</h3>
          <p>Coordenador de Comunicação</p>
          <a
            href="https://www.linkedin.com/in/matheus-henrique-30b0951b7/"
            target="_blank"
          ></a> </Membro
      ></v-slide-item>

      <v-slide-item>
        <Membro>
          <img src="../assets/membros/4331-carol-vivan.png" alt="" />
          <h3>Ana Carolina</h3>
          <p>Analista de Marketing</p>
          <a href="https://www.linkedin.com/in/ana-carolina-vivan-541b26224/" target="_blank"></a> </Membro
      ></v-slide-item>

      <v-slide-item>
        <Membro>
          <img src="../assets/membros/4453-augusta.png" alt="" />
          <h3>Augusta Alegria</h3>
          <p>Analista de Marketing</p>
          <a
            href="https://www.linkedin.com/in/augustaapedroso/"
            target="_blank"
          ></a> </Membro
      ></v-slide-item>

      <v-slide-item>
        <Membro>
          <img src="../assets/membros/4385-jhonatan.png" alt="" />
          <h3>Jhonatan Gomes</h3>
          <p>Analista de Projetos</p>
          <a
            href="https://www.linkedin.com/in/jhonatan-gomes-3976771bb/"
            target="_blank"
          ></a> </Membro
      ></v-slide-item>

      <v-slide-item>
        <Membro>
          <img src="../assets/membros/4326-luiza.png" alt="" />
          <h3>Luiza Eduarda</h3>
          <p>Analista de Projetos</p>
          <a href="https://www.linkedin.com/in/luiza-fialho-990a2418b" target="_blank"></a> </Membro
      ></v-slide-item>

      <v-slide-item>
        <Membro>
          <img src="../assets/membros/4410-mileny.png" alt="" />
          <h3>Mileny Nolasco</h3>
          <p>Analista de Vendas</p>
          <a href="https://www.linkedin.com/in/mileny-nolasco-5a2830216/"></a> </Membro
      ></v-slide-item>

      <v-slide-item>
        <Membro>
          <img src="../assets/membros/4345-stefani.png" alt="" />
          <h3>Stefani de Lima</h3>
          <p>Analista de Vendas</p>
          <a href="https://www.linkedin.com/in/stefani-lima-aa81a318a/"></a> </Membro
      ></v-slide-item>

      <v-slide-item>
        <Membro>
          <img src="../assets/membros/4356-lorena.png" alt="" />
          <h3>Lorena Santana</h3>
          <p>Assistente de Relacionamento</p>
          <a
            href="https://www.linkedin.com/in/lorena-inforzato/"
            target="_blank"
          ></a> </Membro
      ></v-slide-item>

      <v-slide-item>
        <Membro>
          <img src="../assets/membros/natacha.png" alt="" />
          <h3>Natacha Aiala</h3>
          <p>Assistente de Relacionamento</p>
          <a
            href="https://www.linkedin.com/in/natacha-aiala-silva-dos-reis-b69863212/"
            target="_blank"
          ></a> </Membro
      ></v-slide-item>




    </v-slide-group>
  </section>
</template>

<script>
import Membro from "./MembroCard.vue";

export default {
  components: {
    Membro,
  },
};
</script>

<style scoped>
.membros {
  height: 728px;
  display: flex;
  flex-direction: column;
  text-align: center;
}
</style>

<template>
  <section id="solucoes" class="solucoes">
    <h1 class="titulo">soluções</h1>
    <v-slide-group>
      <v-slide-item>
        <SolucaoCard>
          <img src="../assets/solucoes/solucao_1.png" alt="" />
          <h3>diagnóstico de maturidade<br />exportadora</h3>
          <div class="solucoes_text">
            <p>
              Consiste na análise da empresa contratante, levando em
              consideração fatores ligados à sua intenção de
              internacionalização, exportação ou importação. Essa análise leva a
              um relatório de não viabilidade, viabilidade total ou parcial,
              acompanhada do seu diagnóstico e possíveis ajustes para a empresa.
            </p>
          </div>
        </SolucaoCard>
      </v-slide-item>

      <v-slide-item>
        <SolucaoCard>
          <img src="../assets/solucoes/solucao_2.png" alt="" />
          <div class="card-container">
            <h3>estudo de mercado alvo</h3>
            <p class="solucoes-text-2">
              Consiste em uma análise macroeconômica e posteriormente
              microeconômica de países em busca do que melhor se adequa às
              expectativas do contratante e que gere maior viabilidade do
              cliente exportar seu produto ou importar o que deseja.
            </p>
          </div>
        </SolucaoCard>
      </v-slide-item>

      <v-slide-item>
        <SolucaoCard>
          <img src="../assets/solucoes/solucao_3.png" alt="" />
          <div class="card-container">
            <h3>análise tributária</h3>
            <p>
              Esta consultoria consiste no planejamento financeiro do projeto de
              importação ou exportação do contratante por meio de cálculo
              tributário segundo a legislação vigente.
            </p>
          </div>
        </SolucaoCard>
      </v-slide-item>

      <v-slide-item>
        <SolucaoCard>
          <img src="../assets/solucoes/solucao_4.png" alt="" />
          <div class="card-container">
            <h3>planejamento logístico</h3>
            <p>
              Esse planejamento é uma análise da tramitação da mercadoria desde
              sua saída do local de origem até a chegada ao seu destino e esboço
              do meio mais eficaz, conveniente e lucrativo ao cliente.
            </p>
          </div>
        </SolucaoCard>
      </v-slide-item>

      <v-slide-item>
        <SolucaoCard>
          <img src="../assets/solucoes/solucao_5.png" alt="" />
          <div class="card-container">
            <h3>análise burocrática</h3>
            <p>
              Esta análise é referente à um relatório contendo os procedimentos
              e documentos que serão necessários ao contratante para dar ínicio
              ao processo de internacionalização desejado.
            </p>
          </div>
        </SolucaoCard>
      </v-slide-item>

      <v-slide-item>
        <SolucaoCard>
          <img src="../assets/solucoes/solucao_6.png" alt="" />
          <div class="card-container">
            <h3>tradução e interprete</h3>
            <p>
              Consiste na tradução de conteúdos da língua estrangeira para o
              português e vice-versa.
            </p>
          </div>
        </SolucaoCard>
      </v-slide-item>

      <v-slide-item>
        <SolucaoCard>
          <img src="../assets/solucoes/solucao_7.png" alt="" />
          <div class="card-container">
            <h3>representação comercial</h3>
            <p>
              Consiste na elaboração de um plano de todos os passos para levar
              nosso cliente e a empresa do mesmo, caso houver, em eventos do seu
              agrado. Esse planejamento expressa-se na execução de todos os
              serviços necessários para um evento.
            </p>
          </div>
        </SolucaoCard>
      </v-slide-item>

      <v-slide-item>
        <SolucaoCard>
          <img src="../assets/solucoes/solucao_8.png" alt="" />
          <div class="card-container">
            <h3>captação de recursos</h3>
            <p>
              A captação de recursos é o processo estruturado e desenvolvido
              para fomentar e concretizar ideias atrativas com contribuições
              voluntárias de indíviduos, empresas, fundos monetários, governos e
              outras organizações por meio de editais e investimentos diretos.
            </p>
          </div>
        </SolucaoCard>
      </v-slide-item>

      <v-slide-item>
        <SolucaoCard>
          <img src="../assets/solucoes/solucao_9.png" alt="" />
          <div class="card-container">
            <h3>dupla nacionalidade e<br />vistos</h3>
            <p>
              Nesse serviço a Ínteri Jr. irá auxiliar na retirada de dupla
              nacionalidade e/ou vistos internacionais. Essa consultoria
              consiste em um levantamento de toda documentação necessária para o
              processo, juntamente com indicação de melhor lugar para envio e/ou
              marcação e auxílio no preenchimento de formulários.
            </p>
          </div>
        </SolucaoCard>
      </v-slide-item>

      <v-slide-item>
        <SolucaoCard>
          <img style="height: 90px" src="../assets/solucoes/solucao_10.png" alt="" />
          <div class="card-container">
            <h3>análise de danos e risco</h3>
            <p class="solucoes-text-2">
              Consultoria que consiste no levante de informações a respeito de
              importações e exportações, estudo sobre a parte econômica e
              mercadológica da empresa, somando-se ao estudo de microeconomia,
              macroeconomia e em relação ao mercado estrangeiro e suas
              influências.
            </p>
          </div>
        </SolucaoCard>
      </v-slide-item>
    </v-slide-group>
  </section>
</template>

<script>
import SolucaoCard from "./SolucoesCard";

export default {
  components: {
    SolucaoCard,
  },
};
</script>

<style scoped>
.solucoes {
  display: flex;
  flex-direction: column;
  height: 728px;
  background-color: #159ad1;
  text-align: center;
  margin-top: 50px;
}

.solucoes-text-2{
  position: relative;
  left: 20px;
}
</style>

<template>
  <div id="app">
    <Header />
    <ApresentacaoSection />
    <QuemSomos />
    <Solucoes />
    <Depoimentos />
    <ArtigosTela />
    <Membros />
    <FaleConosco @send="sendEmail" />
    <Footer />
    <Mega />
  </div>
</template>

<script>
import Header from '../src/components/HeaderComponent.vue'
import ApresentacaoSection from '../src/components/ApresentacaoSection.vue'
import QuemSomos from '../src/components/QuemSomos.vue'
import Solucoes from '../src/components/SolucoesSection.vue'
import FaleConosco from '../src/components/FaleConosco.vue'
import Depoimentos from '../src/components/DepoimentosSection.vue'
import Membros from '../src/components/MembrosSection.vue'
import ArtigosTela from '../src/components/ArtigosTela.vue'
import Footer from '../src/components/FooterComponent.vue'
import Mega from '../src/components/MegaSign.vue'

import axios from 'axios'
import { data } from '../src/components/FaleConosco.vue'

export default {
  name: 'App',
  components: {
    Header,
    ApresentacaoSection,
    QuemSomos,
    Solucoes,
    Depoimentos,
    ArtigosTela,
    Membros,
    FaleConosco,
    Footer,
    Mega
  },
  methods: {
    sendEmail() {
      axios
        .post('http://localhost:4000', data)
        .then(response => console.log(response))
    }
  }
}
</script>

<style>
@font-face {
  font-family: 'harabara';
  src: url('../src/assets/Harabara.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'montserrat';
  src: url('../src/assets/Montserrat.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

:root {
  font-size: 16px;
  --font: 'harabara', sans-serif;
  --font2: 'Montserrat', sans-serif;
}

* {
  box-sizing: border-box;
  padding: 0%;
  margin: 0px;
}

.titulo {
  font-family: 'Harabara';
  font-weight: 700;
  font-size: 49px;
  color: #ffffff;
  margin: 40px;
}
</style>

<template>
  <div class="card">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style>
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 360px;
  height: 480px;
  margin: 0px 40px;
  border-radius: 24px;
  font-family: "Harabara";
  color: #ffffff;
  border: 5px solid white;
}

.card img {
  margin: 20px;
}

.card h3 {
  font-weight: 700;
  font-size: 31px;
  width: 100%;
  margin-bottom: 5%;
}

.card p {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;
  width: 100%;
  width: 270px;

  word-break: break-word;
  white-space: normal;
}
</style>
